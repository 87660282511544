// Add empty lines so the same keys in en and ja have the same line number
//
export const INTL_DICT_EN = {
  'account-info.otp.scan-qr': 'Scan QR code',
  'account-info.otp.verify': 'Verify OTP',
  'account-info.title': 'Personal info',
  'account-info.update.submit': 'Save changes',
  'account-info.update.title': 'Update account information',
  'account.field.company-name': 'Company name',
  'account.field.email': 'Email',
  'account.field.first-name-kana': 'First name (Kana)',
  'account.field.first-name': 'First name',
  'account.field.full-name-kana': 'Full name (Kana)',
  'account.field.full-name': 'Full name',
  'account.field.last-name-kana': 'Last name (Kana)',
  'account.field.last-name': 'Last name',
  'account.field.locale': 'Language',
  'account.field.name': 'Name',
  'account.field.password.helper': 'Password must have at least 10 characters.',
  'account.field.password': 'Password',
  'account.field.phone': 'Phone',
  'account.info.title': 'Account information',
  'account.info.update': 'Update',
  'account.manage.security.2FA': 'Two factor authentication',
  'account.manage.security.helper': 'Settings and recommendations to help you keep your account secure',
  'account.manage.security.title': 'Security',
  'account.manage.title': 'Manage your account',
  'account.reset-password.description.1': 'Please create a new password to access your account.',
  'account.reset-password.description.2': ' After a new password is created, you will be redirected to the sign in page to access your account with this new password.',
  'account.signout': 'Sign out',
  'all.error.retry': 'Please try again or contact us.',
  'all.error.review': 'Please review your form submission and try again.',
  'annotation.field.module': 'Module',
  'annotation.module.create.title': 'Create module',
  'annotation.module.edit.submit': 'Edit',
  'annotation.module.edit.title': 'Edit module',
  'app.header.user-guide': 'User Guide',
  'app.name.sekisan': 'Sekisan',
  'app.notify.hide': 'Hide',
  'app.notify.learn-more': 'Learn more',
  'app.notify.update-version': 'New version: ',
  'category.action.delete': 'Delete category',
  'category.add-pages.pages': 'Pages',
  'category.categories.label': 'categories',
  'category.create.error': 'Could not create category',
  'category.create.field.name': 'Name',
  'category.create.field.parentID.hint': 'Category can be created inside another category.',
  'category.create.field.parentID': 'Inside another category',
  'category.create.submit': 'Create category',
  'category.create.title': 'Create category',
  'category.create.top-level': 'No (create at top level)',
  'category.delete.error.title': 'Could not delete this category',
  'category.delete.help': 'Are you sure you want to delete this category',
  'category.delete.helper': 'Are you sure you want to permanently delete the category    ',
  'category.delete.rules.1': 'All documents',
  'category.delete.rules.2': 'All annotations',
  'category.delete.rules.3': 'This action cannot be undone. To proceed, please type the name of the category to confirm deletion:',
  'category.delete.rules': 'This will delete all associated data, including:',
  'category.delete.title': 'Delete category',
  'category.edit.cancel': 'Cancel',
  'category.edit.submit': 'Edit category',
  'category.edit.title': 'Edit category',
  'category.field.name': 'Name',
  'category.information.edit': 'Edit info',
  'category.label': 'Category',
  'category.list.create': 'Create category',
  'category.list.filter.all_pages': 'All pages',
  'category.list.filter.equipment': 'Equipment',
  'category.list.filter.with_annotations': 'Page with annotations',
  'category.list.filter.without_annotations': 'Page without annotations',
  'category.list.report.annotations': 'Annotations',
  'category.list.title': 'Categories',
  'category.page.rename': 'Rename',
  'conctruction.delete.close-quote': '\'',
  'conctruction.delete.open-quote': '\'',
  'construction.action.delete': 'Delete construction',
  'construction.categories.empty': 'No categories',
  'construction.category.action.annotate': 'Annotate',
  'construction.category.action.edit': 'Edit',
  'construction.category.annotations': 'Annotations',
  'construction.category.pages': 'Pages ',
  'construction.category': 'Construction category',
  'construction.create.submit': 'Create construction',
  'construction.create.title': 'Create construction',
  'construction.delete.error-field': 'Construction name must be same with ',
  'construction.delete.error.title': 'Could not delete this construction',
  'construction.delete.help': 'Are you sure you want to permanently delete the construction',
  'construction.delete.rules.1': 'All categories',
  'construction.delete.rules.2': 'All documents',
  'construction.delete.rules.3': 'All annotations',
  'construction.delete.rules.4 {{.name}}': 'This action cannot be undone. To proceed, please type the name of the construction to confirm deletion:',
  'construction.delete.rules': '? This action will remove all associated data, including:',
  'construction.delete.title': 'Delete construction',
  'construction.edit.title': 'Edit',
  'construction.explore.title': 'Explore',
  'construction.field.category': 'Category',
  'construction.field.code': 'Code',
  'construction.field.name': 'Name',
  'construction.field.preset': 'Preset',
  'construction.information.edit': 'Edit info',
  'construction.information.overview': 'Overview',
  'construction.invitation.create.role-viewer': 'Cannot invite other members because you are not a construction owner. Please ask one of the construction owners for help.',
  'construction.list.create': 'Create construction',
  'construction.list.title': 'Constructions',
  'construction.member.add.error': 'Could not add this member',
  'construction.member.add.guideline.1': '“Editor” can create categories, add pages, and annotate them.',
  'construction.member.add.guideline.2': '“Viewer” can view annotations, summary, and download them.',
  'construction.member.add.guideline': 'Add members from your organization to collaborate on this construction:',
  'construction.member.add.hint.1': 'Their role in this construction:',
  'construction.member.add.hint.2': 'Editor can create categories, add pages, and annotate them.',
  'construction.member.add.hint.3': 'Viewer can view annotations, summary, and download them.',
  'construction.member.add.hint': 'You can only add members of the organization to this construction. If you need to add someone else, first invite them to join the organization.',
  'construction.member.add.member': 'Member',
  'construction.member.add.submit': 'Invite member',
  'construction.member.add.title': 'Add member',
  'construction.member.editors': 'editors',
  'construction.member.list.title': 'Members',
  'construction.member.remove.cancel': 'Cancel',
  'construction.member.remove.error.title': 'Could not remove this member',
  'construction.member.remove.helper {{.email}}': 'Are you sure you want to remove {{.email}} from this construction?',
  'construction.member.remove.helper.2': 'This will prevent them from accessing any categories, uploaded documents, or annotations in this project.',
  'construction.member.remove.helper.3': 'However, they will still be a member of the organization, and you can add them back to this construction in the future.',
  'construction.member.remove.submit': 'Remove member',
  'construction.member.remove': 'Remove member',
  'construction.module.create.error': 'Could not create construction module',
  'construction.module.create.submit': 'Create',
  'construction.module.edit.error': 'Could not edit construction module',
  'construction.module.field.name': 'Name',
  'construction.module.field.quantity': 'Quantity',
  'construction.preset.create': 'Create preset',
  'construction.preset.helper.1': 'Presets are predefined configurations for constructions in your organization. For example, a preset can define a set of “material” and “size” for an equipment.',
  'construction.preset.helper.2': 'Choosing a preset is not required but highly recommended to start faster and ensure consistent data.',
  'construction.preset.helper.3': 'You cannot choose a preset after creating the construction.',
  'construction.preset.list.title': 'Presets',
  'construction.property.create': 'Create property',
  'construction.property.update-successfully': 'Update equipment property successfully',
  'construction.role.editor.title': 'Editor',
  'construction.role.owner.title': 'Owner',
  'construction.role.viewer.title': 'Viewer',
  'construction.title {{.name}}': 'Construction {{.name}}',
  'construction.title': 'Construction',
  'construction.update.error': 'Could not update this construction',
  'construction.update.submit': 'Save changes',
  'construction.update.title': 'Edit construction info',
  'dialog.action.cancel': 'Cancel',
  'error.message': 'An error has occurred. Please try again',
  'file.error.message': 'File is required',
  'form.error.required_field': 'This field is required',
  'Greetings {{.name}}': 'Greetings {{.name}}',
  'Greetings': 'Greetings',
  'kit.error-alert.copied': 'Copied',
  'kit.error-alert.detail': 'Copy Error',
  'locale.english': 'English',
  'locale.japan': 'Japanese',
  'No something': '',
  'org.construction.title': 'Constructions',
  'org.field.address': 'Address',
  'org.field.email.hint': 'We will send an instruction to join the organization to their email.',
  'org.field.email.rules.1': 'Owners can update organizational details, including adding or removing members.',
  'org.field.email.rules.2': 'Members can create new constructions and join existing ones if invited.',
  'org.field.email.rules': 'Their role in this organization:',
  'org.field.email': 'Email',
  'org.field.name': 'Name',
  'org.field.phone': 'Phone',
  'org.field.tax-code': 'Tax code',
  'org.invitation.create.error.help': 'Please review your form submission and try again.',
  'org.invitation.create.error.title': 'Could not invite this member',
  'org.invitation.create.role-member': 'Cannot invite other members because you are not the an organization owner. Please ask one of your organization owners for help.',
  'org.invitation.create.submit': 'Invite member',
  'org.invitation.create.title': 'Invite member',
  'org.invitation.invite.expired.on': 'Invitation will expire on',
  'org.invitation.invite.guideline.1': '“Owners” can update organizational details, including adding or removing members.',
  'org.invitation.invite.guideline.2': ' “Members” can create new constructions and join existing ones if invited.',
  'org.invitation.invite.guideline': 'Invite members to collaborate on constructions:',
  'org.invitation.invite.invited.on': 'Invited on',
  'org.invitation.list.invite': 'Invite member',
  'org.invite.member.field.locale.hint': 'We will use this language in the email and the app for them. They can change the language anytime.',
  'org.member.remove.cancel': 'Cancel',
  'org.member.remove.error.title': 'Could not remove this member',
  'org.member.remove.helper {{.email}}': ' Are you sure you want to remove {{.email}} from the organization?',
  'org.member.remove.helper.2': 'This will also remove them from all construction projects. If you only want to remove them from specific projects, go to those projects.',
  'org.member.remove.helper.3': 'You can invite them again in the future, but you will need to add them to any projects as needed.',
  'org.member.remove.submit': 'Remove member',
  'org.member.remove.title': 'Remove member',
  'org.member.revoke.invitation.helper {{.email}}': 'Are you sure you want to cancel the invitation for {{.email}} to join the organization?',
  'org.member.revoke.invitation.helper.2': 'This will not delete their invitation email, but the link inside will no longer work.',
  'org.member.revoke.invitation.helper.3': 'You can invite them again later.',
  'org.member.revoke.invitation.submit': 'Revoke invitation',
  'org.member.revoke.invitation.title': 'Cancel invitation',
  'org.member.table.remove': 'Remove',
  'org.member.title': 'Members',
  'org.member.unit': 'member(s)',
  'org.overview.organization.edit': 'Edit',
  'org.overview.organization.go': 'Go to organization',
  'org.preset.edit': 'Edit info',
  'org.preset.guideline.1': ' For example, a preset can define a set of “material” and “size” for an equipment.',
  'org.preset.guideline.2': 'Members can choose a preset when creating new constructions to start faster and ensure consistent data.',
  'org.preset.guideline': 'Presets are predefined configurations for constructions in your organization.',
  'org.preset.label': 'Preset',
  'org.preset.property.create': 'Create property',
  'org.preset.title': 'Presets',
  'org.property.update-successfully': 'Update equipment property successfully',
  'org.role.label': 'Role',
  'org.role.member.title': 'Member',
  'org.role.owner.title': 'Owner',
  'org.stats.constructions': 'Construction',
  'org.stats.constUnit': 'constructions',
  'org.stats.maxNumConstruction': 'Maximum number of construction',
  'org.stats.maxOverPage': 'Over page',
  'org.stats.numAdditionalAskAI': 'Number additional Ask AI',
  'org.stats.numAdditionalDownload': 'Number additional download',
  'org.stats.numConstruction': 'Number of construction',
  'org.stats.numOverPage': 'Number over page',
  'org.stats.numPagePerSeat': 'Number page per seat',
  'org.stats.numSeat': 'Number of seat',
  'org.stats.page': 'Page',
  'org.stats.pagePoolCapacity': 'Page pool capacity',
  'org.stats.pageUnit': 'pages',
  'org.stats.pageUsed': 'Page used',
  'org.stats.standardPlan': 'Standard plan',
  'org.stats.storage': 'Storage',
  'org.stats.storageCapacity': 'Storage capacity',
  'org.stats.storageUsed': 'Storage used',
  'org.stats.title': 'Statistics',
  'org.title': 'Organization',
  'org.update.error.help': 'Please review your form submission and try again.',
  'org.update.error.title': 'Could not update this organisation',
  'org.update.submit': 'Save changes',
  'org.update.title': 'Update organization info',
  'otp.back': 'Back',
  'otp.cancel': 'Cancel',
  'otp.field.otp.error': 'Error',
  'otp.field.otp.help': 'Authentication code',
  'otp.field.otp.invalid': 'Invalid OTP',
  'otp.field.otp': 'OTP',
  'otp.help': 'Please use Google Authenticator to scan this QR code, then input received OTP',
  'otp.next': 'Next',
  'otp.submit': 'Enable 2FA',
  'otp.title': 'Enable two-factor authentication',
  'page-not-found': '404 Page not found',
  'page.action.add.pages': 'Add more pages',
  'page.action.more': 'More',
  'page.add.after.page.title': 'Add after this page',
  'page.add.before.page.title': 'Add before this page',
  'page.add.error.title': 'Could not add this page',
  'page.delete.cancel': 'Cancel',
  'page.delete.error.title': 'Could not delete this page',
  'page.delete.helper.1': '? This will remove all annotations on the page.',
  'page.delete.helper.2': 'If you want to keep the annotations but replace the page’s drawing, use “Replace” from the “More” menu.',
  'page.delete.helper.3': ' This action cannot be undone.',
  'page.delete.helper': 'Are you sure you want to permanently delete the page',
  'page.delete.multiple.title': 'Delete pages',
  'page.delete.submit': 'Delete page',
  'page.delete.title': 'Delete page',
  'page.duplicate.action.cancel': 'Cancel',
  'page.duplicate.action.submit': 'Duplicate',
  'page.duplicate.error.title': 'Could not duplicate this page',
  'page.duplicate.help': 'Are you sure you want to duplicate this page? This will duplicate only the page, excluding the annotations.',
  'page.duplicate.title': 'Duplicate page',
  'page.label': 'Page',
  'page.list.title': 'Pages',
  'page.overview.annotate': 'Annotate',
  'page.overview.more.delete': 'Delete',
  'page.overview.more.duplicate': 'Duplicate',
  'page.overview.more.rename': 'Rename',
  'page.overview.more.replace': 'Replace',
  'page.print.category.helper.1': 'This will generate a PDF file with all annotated pages in this category. To print a specific page, use the "More" menu at that page.',
  'page.print.failed': 'Failed to print',
  'page.print.helper.1': 'This will generate a PDF file with only this page. To print all annotated pages, go to the category screen and choose "Print pages".',
  'page.print.helper.2': 'Once created, the file will automatically download. You may be asked to choose a location to save it.',
  'page.print.helper.3': 'Click “Start” to begin.',
  'page.print.helper.creating_pdf': 'Creating PDF ...',
  'page.print.page.label': 'Page',
  'page.print.page.title': 'Print',
  'page.print.pages.title': 'Print pages',
  'page.print.submit': 'Start',
  'page.print.title': 'Print page',
  'page.rename.cancel': 'Cancel',
  'page.rename.error.title': 'Could not rename this page',
  'page.rename.error': 'Could not rename page',
  'page.rename.submit': 'Rename page',
  'page.rename.title': 'Rename page',
  'page.replace.error.title': 'Could not replace this page',
  'page.replace.error': 'Could not replace the page',
  'page.replace.field.file.hint': 'Choose the PDF file that has the new page.',
  'page.replace.field.file.label': 'Documentation',
  'page.replace.field.page.hint': 'Enter the number of the page you want to use from the PDF above.',
  'page.replace.field.page': 'Page',
  'page.replace.submit': 'Replace page',
  'page.replace.title': 'Replace page',
  'page.upload.cancel': 'Cancel',
  'page.upload.field.file.hint': 'Upload a PDF file to add pages from.',
  'page.upload.field.file': 'Document',
  'page.upload.options.allPages': 'Include all pages from the document',
  'page.upload.options.specificPages': 'Choose specific pages to add',
  'page.upload.rules': 'These pages will be appended to the category\'s end. To insert pages before or after a specific page, use the \'More\' menu on that page.',
  'page.upload.specificPages.hint': 'Enter the page numbers, separated by commas',
  'page.upload.submit': 'Add pages',
  'page.upload.title': 'Add pages',
  'preset.action.delete': 'Delete',
  'preset.create.error.title': 'Could not edit this preset',
  'preset.delete.error.title': 'Could not delete preset',
  'preset.edit.error.title': 'Could not edit this preset',
  'preset.equipment.properties': 'Equipment properties',
  'preset.equipment.property.copy.error': 'Could not copy this property',
  'preset.equipment.property.create.form.error.title': 'Could not create property',
  'preset.equipment.property.create': 'Create equipment',
  'preset.equipment.property.delete.error': 'Could not delete this property',
  'preset.equipment.property.label': 'Property ',
  'preset.equipment.property.tree.copy': 'Copy to other equipment',
  'preset.equipment.property.tree.create': 'Create',
  'preset.equipment.property.tree.delete': 'Delete',
  'preset.equipment.property.tree.save': 'Save',
  'preset.equipment.property.update.form.error.title': 'Could not update property',
  'preset.type.global_preset': 'Global preset',
  'preset.type.org_preset': 'Org preset',
  'report-fire.field.category': 'Category name: ',
  'report-fire.field.construction': 'Construction name: ',
  'report-fire.header.construction_area': 'Construction area',
  'report-fire.header.equipment': 'Equipments',
  'report-fire.header.material': 'Material',
  'report-fire.header.sub-total': 'Sub total',
  'report-fire.header.total': 'Total',
  'report-fire.tab.pieces': 'Piece Summary',
  'report-fire.tab.pipes': 'Pipe Summary',
  'report.download.format_csv': 'CSV format',
  'report.download.format_excel': 'Excel format',
  'report.download': 'Download',
  'report.field.category': 'Category',
  'report.field.construction_code': 'Construction code',
  'report.field.construction_name': 'Construction name',
  'report.field.date': 'Date',
  'report.field.page': 'Page',
  'report.header.construction_area': 'Construction area',
  'report.header.insulation': 'Insulation',
  'report.header.material': 'Material',
  'report.header.object': 'Object',
  'report.header.size': 'Size',
  'report.header.total': 'Total',
  'report.header.type': 'Type',
  'report.header.unit': 'Unit',
  'report.insulation.non': 'Non',
  'report.module.none': 'No module',
  'report.summary': 'Summary',
  'report.template': 'Template',
  'report.title.summary': 'SUMMARY',
  'report.type.fd': 'FD',
  'report.type.nonfd': 'Non FD',
  'report.unit.piece': 'piece',
  'reset-password.accept.form.error.help': 'Please review your form submission and try again.',
  'reset-password.accept.form.error.title': 'Could not reset password',
  'reset-password.accept.form.help': 'Please enter the email address associated with your AI Sekisan account, and we’ll send you a link to reset your password.',
  'reset-password.accept.form.submit': 'Create password',
  'reset-password.accept.success.go': 'Go to sign in',
  'reset-password.accept.success.help': 'Sign in to continue',
  'reset-password.contact-support': 'Contact Support',
  'reset-password.email.sent.description': 'We\'ve sent a password reset link to the email address you provided.',
  'reset-password.email.sent.go-back': 'Go back',
  'reset-password.email.sent.help.1': 'Sometimes emails take a little while to arrive or end up in the spam folder.',
  'reset-password.email.sent.help.2': 'If you made a typo or used a different email address, please go back and enter it again.',
  'reset-password.email.sent': 'Check your inbox',
  'reset-password.error.help': 'Review the form and try again.',
  'reset-password.error.title': 'Could not reset password',
  'reset-password.need-assistance': 'Need assistance?',
  'reset-password.remember-password': 'Remembered your password after all',
  'reset-password.submit': 'Send reset link',
  'reset-password.title': 'Reset password',
  'sign-in.otp.error.help': 'Invalid OTP',
  'sign-in.otp.error.title': 'Error',
  'sign-in.otp.field.otp': 'Authentication code',
  'sign-in.otp.help': 'Please enter the 6-digit code from your authentication device. This extra step verifies your identity and keeps your account secure.',
  'sign-in.otp.verification.title': 'Verify your identity',
  'sign-in.reset': 'Forgot password',
  'sign-in.self.error.help': 'Please review your form submission and try again.',
  'sign-in.self.error.title': 'Could not sign in',
  'sign-in.submit': 'Sign in',
  'sign-in.title': 'Sign in',
  'sign-in.trouble_sign_in': 'Trouble signing in?',
  'sign-up.accept.error.help.1': 'It seems there\'s an issue with this invitation. It\'s likely expired, as our invitations are time-sensitive for security reasons.',
  'sign-up.accept.error.help.2': 'To get a new invitation, please ask the sender to resend it.',
  'sign-up.accept.error': 'Invitation issue',
  'sign-up.accept.form.error.help': 'Please review your form submission and try again.',
  'sign-up.accept.form.error.title': 'Could not join organisation',
  'sign-up.accept.form.submit': 'Accept invitation',
  'sign-up.accept.form.title {{.orgName}}': 'Join {{.orgName}}',
  'sign-up.accept.success.go': 'Go to sign in',
  'sign-up.accept.success.help': 'Sign in to explore the organization’s constructions.',
  'sign-up.accept.success.title {{.orgName}}': 'Welcome to {{.orgName}}',
  'table.no.data': 'No data available',
  'Welcome back': '',
}
