import { LATEST_VERSION, useNotifyVersion } from './help';
import { makeStyles, tokens, Caption1, Button } from '@aisekisan/bond';
import { useT } from '@/libs/intl/useT';

const useStyles = makeStyles({
  banner: {
    borderRadius: tokens.borderRadiusXLarge,
    width: 'auto',
    backgroundColor: tokens.colorBrandForeground1,
    padding: `${tokens.spacingVerticalXXS} ${tokens.spacingVerticalS}`,
    display: 'flex',
    justifyContent: 'space-between',
    gap: tokens.spacingHorizontalXS,
    alignItems: 'center',
    color: 'white',
    height: '28px',
    '@media(max-width: 1024px)': {
      position: 'fixed',
      left: 'calc(42% - 45px)',
      transform: 'translateX(-50%,0)',
      zIndex: 1,
      opacity: 0.9,
  },
},
  message: {
    display: 'flex',
    alignItems: 'baseline'
  },
  action: {
    display: 'flex',
    gap: tokens.spacingHorizontalXS,
  },
  button: {
    width: 'auto',
    fontSize: '12px',
    color: 'white',
    minWidth: '36px',
    height: '22px'
  }
})

export function AppNotifyVersion() {
  const s = useStyles()

  const { updateVersion, version } = useNotifyVersion()
  const t = useT()

  if (version.seen === 'true')
    return null

  const onClose = () => {
    updateVersion({ ...version, seen: 'true' })
  }

  return (
    <div className={s.banner}>
      <Caption1 className={s.message}>
        {`${t('app.notify.update-version')} ${LATEST_VERSION}`}
      </Caption1>
      <div className={s.action}>
        <Button
          as="a"
          size="small"
          appearance='primary'
          className={s.button}
          href="https://docs.aisekisan.com"
          target="_blank"
          onClick={onClose}
        >
          {t('app.notify.learn-more')}
        </Button >
        <Button
          appearance='subtle'
          size="small"
          className={s.button}
          onClick={() => onClose()}>
          {t('app.notify.hide')}
        </Button>
      </div>
    </div>
  )
}